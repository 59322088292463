import React from "react";
import Footer from "src/components/Footer_it";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="disclosureText">
        </div>
        <div id="page">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/it/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="first has-sub-nav">
                    <a className="es_header" href="/it/prodotti/" target="_self">
                      Prodotti
                    </a>
                    <ul>
                      <li>
                        <a href="/it/prodotti/hydro-infusion/">
                          Hydro Infusion
                        </a>
                      </li>
                      {/* <li>
                        <a href="/it/prodotti/active-protect/">
                          Active &amp; Protect
                        </a>
                      </li> */}
                      <li>
                        <a href="/it/prodotti/moisturising/">Moisturising</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/tan_protect/">
                          Tan &amp; Protect
                        </a>
                      </li>
                      {/* <li>
                        <a href="/it/prodotti/instantglow/">Instant Glow</a>
                      </li> */}
                      <li>
                        <a href="/it/prodotti/allergy/">Allergy</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/mountain/">Mountain</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/aftersun/">After Sun</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/gallery/">Gallery</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a className="es_header" href="/it/our-heritage/" target="_self">
                      Storia
                    </a>
                  </li>
                  <li>
                    <a className="es_header" href="/it/know_the_sun/" target="_self">
                      Conosci il sole
                    </a>
                  </li>
                {/*<li>
                    <a className="es_header" href="/it/Ma-Dove-Sei-Stata/" target="_self">
                      Ma Dove Sei Stata 2019
                    </a>
                  </li>*/}
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      Paesi{" "}
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="/en/legal/private-policy/">INT</a>
                      </li>
                      <li>
                        <a href="/uk/legal/private-policy/">UK</a>
                      </li>
                      <li>
                        <a href="/es/legal/politicia-de-privacidad/">ES</a>
                      </li>
                      <li>
                        <a href="/pt/legal/politica_de_privacidade/">PT</a>
                      </li>
                      <li>
                        <a href="/it/legal/private-policy/">IT</a>
                      </li>
                      <li>
                        <a href="/gr/legal/private-policy/">GR</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            <section id="desktop-hero-wrapper" className="index-hero">
              <div id="bgImage">
                <div className="areaInner"></div>
              </div>
            </section>
            <section id="content">
              <div className="innerContent">
                <h2>Privacy Policy</h2>
                <p> </p>
                <p>
                  <strong>Johnson &amp; Johnson S.p.A</strong>. è attenta alla
                  tutela della privacy e desidera che l’utente conosca nello
                  specifico le modalità in base alle quali la società raccoglie,
                  usa e divulga le sue informazioni. La presente Privacy Policy
                  descrive le procedure adottate dalla società in relazione alle
                  informazioni che la stessa o i suoi fornitori raccolgono
                  tramite il sito Web o le applicazioni mobili (di seguito, il “
                  <strong>Servizio</strong>”) gestiti e controllati dalla
                  società e dai quali l’utente accede alla presente Privacy
                  Policy. Fornendoci informazioni personali o utilizzando il
                  Servizio, l’utente conferma di aver letto e compreso la
                  presente Privacy Policy.{" "}
                </p>
                <h2>UTILIZZO DA PARTE DI MINORI</h2>
                <p>
                  Il Servizio non è rivolto a soggetti di un’età inferiore a 16;
                   la società chiede che tali soggetti non forniscano
                  Informazioni personali attraverso il Servizio. Se il proprio
                  figlio ha inviato informazioni personali e si desidera
                  richiedere la rimozione di tali informazioni, contattarci come
                  indicato di seguito nella sezione Contatti.
                </p>
                <h2>
                  <strong>RACCOLTA DI INFORMAZIONI</strong>
                </h2>
                <p>
                  Potremmo chiedere all’utente di inviare informazioni personali
                  al fine di usufruire di determinate funzioni (come iscrizioni
                  alle newsletter, suggerimenti/indicazioni o elaborazione degli
                  ordini) o di partecipare ad attività particolari (come
                  concorsi a premi o altre promozioni). L’utente sarà informato
                  sui tipi di dati personali obbligatori e su quelli opzionali.
                </p>
                <p>
                La società potrebbe associare le informazioni inviate dall’utente ad altri suoi 
                dati personali raccolti, sia online che offline, inclusa ad esempio la cronologia 
                degli acquisti. La società potrebbe anche associare tali informazioni con le informazioni 
                ricevute sull’utente da altre affiliate del Gruppo Kenvue.
                </p>
                <p>
                  Se l’utente invia alla società dati personali relativi ad un
                  altro individuo, l’utente deve confermare di essere
                  autorizzato a farlo e di avere l’autorità necessaria per
                  permettere a noi di utilizzare tali dati personali ai sensi di
                  questa Privacy Policy.
                </p>
                <h2>INFORMAZIONI SENSIBILI</h2>
                <p>
                Salvo richiesta esplicita e specifica da parte nostra, la società chiede all’utente di 
                non inviarci, né di divulgare, dati personali sensibili (ad es. i dati personali relativi 
                all’origine razziale o etnica, alle opinioni politiche, alle convinzioni religiose o 
                filosofiche, alla salute o alle condizioni mediche, alla vita sessuale o all’orientamento 
                sessuale, alla fedina penale, all'appartenenza sindacale, 
                i dati biometrici o genetici) su o attraverso il Servizio o altrimenti alla società stessa.{" "}
                </p>
                <h2>RACCOLTA AUTOMATICA E UTILIZZO DEI DATI PERSONALI</h2>
                <p>
                  La società e i suoi fornitori di servizi potrebbero
                  raccogliere alcuni dati personali in maniera automatica quando
                  l’utente naviga all’interno del Servizio. Si invita l’utente a
                  leggere la{" "}
                  <a href="/it/legal/cookie-policy/" target="_blank">
                    Cookie Policy
                  </a>{" "}
                  per informazioni dettagliate su questa e su altre tecnologie di monitoraggio 
                  utilizzate nel Servizio. Tranne nei casi consentiti dalla legge applicabile, 
                  inseriamo i cookie solo dopo aver ricevuto il Suo consenso tramite il banner 
                  dei cookie o avere modificato le impostazioni nel pannello del Suo browser. 
                  La Cookie Policy include informazioni su come disabilitare queste tecnologie. 
                </p>
                <p>
                  La società e i suoi fornitori di servizi possono inoltre
                  raccogliere automaticamente e utilizzare le informazioni nei
                  seguenti modi:
                </p>
                <ul>
                  <li>
                    <strong>Dal browser dell’utente:</strong> dalla maggior
                    parte dei browser vengono raccolte alcune informazioni tra
                    cui l’indirizzo MAC (Media Access Control), il tipo di
                    computer (Windows o Mac), la risoluzione dello schermo, il
                    nome e la versione del sistema operativo e il tipo e la
                    versione del browser Internet. Se si accede al Servizio da
                    un dispositivo mobile, la società può raccogliere
                    informazioni simili, tra cui il tipo di dispositivo e
                    l’identificatore. La società utilizza queste informazioni
                    per verificare che il Servizio funzioni correttamente.
                    <br />
                    <br />
                  </li>
                  <li>
                    <strong>Indirizzo IP:</strong> l’indirizzo IP è costituito
                    da un numero assegnato automaticamente al computer dal
                    provider di servizi Internet (Internet Service Provider,
                    ISP) dell’utente. Un indirizzo IP viene identificato e
                    registrato automaticamente nei file di registro del server
                    della società a ogni accesso degli utenti al Servizio,
                    insieme alla data e all'ora della visita e alle pagine
                    visitate. La raccolta degli indirizzi IP rappresenta una
                    pratica normale e viene effettuata automaticamente da molti
                    servizi online. Gli indirizzi IP vengono impiegati dalla
                    società per varie finalità, ad esempio per calcolare i
                    livelli di utilizzo del Servizio, diagnosticare i problemi
                    del server e gestire il Servizio. Dall’indirizzo IP la
                    società potrebbe anche determinare l’ubicazione
                    approssimativa dell’utente.
                    <br />
                    <br />
                  </li>
                  <li>
                    <strong>Informazioni sul dispositivo:</strong> la società
                    può raccogliere informazioni sul dispositivo mobile
                    dell’utente, come l’identificatore univoco del dispositivo,
                    per comprendere in che modo l’utente usa il Servizio.
                  </li>
                </ul>
                <h2>IN CHE MODO LA SOCIETÀ USA E DIVULGA I DATI PERSONALI</h2>
                <p>
                  La società usa e divulga i dati personali dell’utente come
                  indicato al momento della raccolta. Si invita l’utente a
                  consultare la sezione “Scelte e accesso,” riportata di
                  seguito, per comprendere come revocare l'accettazione di
                  alcuni usi e divulgazioni effettuati dalla società.{" "}
                </p>
                <p>
                Ove richiesto dalla legge vigente, la società dovrà ottenere il consenso dell’utente a utilizzare 
                i suoi dati personali al momento della raccolta.  La società può inoltre utilizzare i dati 
                personali dell’utente qualora sia necessario ai fini dell’esecuzione di un contratto, della 
                conformità a un obbligo legale (ad esempio per gli obblighi di farmacovigilanza della società) 
                oppure per i suoi legittimi interessi aziendali. Inoltre, la società potrebbe basarsi su 
                altri requisiti legali, nello specifico al fine di:
                </p>
                <ul>
                  <li>
                    <strong>
                      Mettere a disposizione le funzionalità del Servizio e
                      soddisfare le richieste dell’utente.
                      <br />
                      <br />
                    </strong>
                  </li>
                  <li>
                    Mettere a disposizione dell’utente le funzionalità del
                    Servizio e fornirgli l’assistenza clienti relativa.
                  </li>
                  <li>
                    Rispondere alle domande e soddisfare le richieste
                    dell’utente, ad esempio per inviargli documenti chiesti o
                    avvisi tramite e-mail;
                  </li>
                  <li>
                    Inviargli informazioni importanti in merito al suo rapporto
                    con la società o riguardanti il Servizio, le modifiche ai
                    termini, condizioni e informative e/o altre informazioni
                    amministrative.
                  </li>
                </ul>
                <p>
                  La società svolgerà tali attività per gestire il proprio
                  rapporto contrattuale con l’utente e/o per adempiere ad un
                  obbligo legale.
                </p>
                <ul>
                  <li>
                    <strong>
                      Raggiungere i propri scopi aziendali.
                      <br />
                      <br />
                    </strong>
                  </li>
                  <li>
                    Per l’analisi dei dati, per esempio, al fine di migliorare
                    l’efficienza del Servizio.
                  </li>
                  <li>
                    Per i controlli, al fine di verificare che i propri processi
                    interni funzionino come previsto e siano conformi ai
                    requisiti legali, normativi o contrattuali.
                  </li>
                  <li>
                    Ai fini del monitoraggio di frode e sicurezza, per esempio,
                    per individuare e prevenire attacchi informatici o tentativi
                    di commettere furto di identità.
                  </li>
                  <li>Per lo sviluppo di nuovi prodotti e servizi.</li>
                  <li>
                    Per accrescere, migliorare o modificare il proprio sito
                    internet o i propri prodotti e servizi.{" "}
                  </li>
                  <li>
                    Per identificare le tendenze dell’utilizzo del Servizio, per
                    esempio, grazie alla comprensione di quali parti del proprio
                    Servizio risultino più interessanti per gli utenti.
                  </li>
                  <li>
                    Infine, per determinare l’efficacia delle proprie campagne
                    promozionali, in modo da poterle adattare alle esigenze e
                    agli interessi dei suoi utenti.
                  </li>
                </ul>
                <p>
                  La società svolgerà tali attività per gestire il proprio
                  rapporto contrattuale con l’utente, per adempiere ad un
                  obbligo legale e/o in quanto possieda un interesse legittimo.
                </p>
                <ul>
                  <li>
                    <strong>
                      Analisi delle Informazioni personali per fornire servizi
                      personalizzati.
                      <br />
                      <br />
                    </strong>
                  </li>
                  <li>
                    Per conoscere meglio l’utente, in modo da poter
                    personalizzare le proprie interazioni con quest’ultimo e
                    fornirgli informazioni e/o offerte su misura per i suoi
                    interessi.
                  </li>
                  <li>
                    Per comprendere meglio le preferenze dell’utente, in modo da
                    poter offrire, mediante il Servizio, contenuti ritenuti
                    dalla società come pertinenti ed interessanti per l’utente.
                  </li>
                </ul>
                <p>
                  La società fornirà servizi personalizzati sia con il consenso
                  dell’utente che a fronte di un proprio interesse legittimo.
                </p>
                <p>
                  Inoltre la società divulga le informazioni raccolte attraverso
                  il Servizio:
                </p>
                <ul>
                  <li>
                    alle sue consociate per le finalità descritte nella presente
                    Privacy Policy. Un elenco delle nostre consociate è
                    disponibile all’indirizzo{" "}
                    <a
                      href="https://investors.kenvue.com/financials-reports/sec-filings/default.aspx"
                      target="_blank"
                    >
                      https://investors.kenvue.com/financials-reports/sec-filings/default.aspx
                    </a>{" "}
                    - accedere ai documenti depositati da Kenvue presso la Securities and Exchange Commission. Johnson &amp; Johnson S.p.A. è la parte
                    responsabile della gestione delle Informazioni personali
                    utilizzate congiuntamente;
                    <br />
                    <br />
                  </li>
                  <li>
                    ai suoi partner terzi con i quali offre una promozione in
                    co-branding o co-marketing;
                    <br />
                    <br />
                  </li>
                  <li>
                    ai suoi fornitori di servizi tra cui l’hosting e la
                    moderazione del sito Web, l’hosting di applicazioni mobili,
                    l’analisi dei dati, l’elaborazione dei pagamenti, la
                    consegna degli ordini, la fornitura delle infrastrutture, i
                    servizi IT, l’assistenza clienti, i servizi di consegna di
                    posta elettronica e posta ordinaria, i servizi di audit e
                    altre funzioni che consentono loro di fornire tali servizi;
                    e<br />
                    <br />
                  </li>
                  <li>
                    secondo quanto previsto dalla legislazione vigente, ai
                    soggetti terzi in caso di riorganizzazione, fusione,
                    vendita, condivisione in joint venture, cessione,
                    trasferimento o altra disposizione in toto o in parte
                    dell’attività, del patrimonio o delle azioni della società
                    (anche in relazione a bancarotta o a procedimenti affini).
                  </li>
                </ul>
                <p>
                  Inoltre la società può utilizzare e divulgare le informazioni
                  dell’utente quando lo ritiene necessario od opportuno: (a) per
                  conformarsi a un procedimento giudiziario o alla legislazione
                  vigente, incluse le leggi al di fuori del Paese di residenza
                  dell’utente; (b) secondo quanto previsto dalla legislazione
                  vigente, per rispondere a richieste da parte di autorità
                  pubbliche e governative, incluse quelle che si trovano al di
                  fuori del Paese di residenza dell’utente; (c) per rispettare i
                  propri termini e condizioni; e (d) per tutelare i propri
                  diritti, la privacy, sicurezza o proprietà e/o quelle delle
                  sue consociate, dell’utente o di altri. Inoltre, con il
                  consenso dell’utente, la società può usare e divulgare i dati
                  personali dell’utente in altri modi.{" "}
                </p>
                <p>
                  La società può utilizzare e divulgare i dati personali
                  raccolti automaticamente, come precedentemente descritto in
                  “Raccolta automatica e utilizzo dei dati personali”.
                </p>
                <p>
                  Inoltre, ove consentito dalla legislazione vigente, la società
                  può utilizzare e divulgare informazioni che sono in forma non
                  identificabile a livello personale, per qualsiasi finalità. Se
                  la società associa informazioni non identificabili a livello
                  personale a dati personali (ad esempio associa il nome
                  dell’utente alla sua posizione geografica), tali informazioni
                  associate saranno considerate dati personali finché resteranno
                  unite.
                </p>
                <h2>SCELTE E ACCESSO</h2>
                <p>
                  <strong>
                    Le scelte dell’utente relative all’uso e alla divulgazione
                    da parte della società di dati personali
                  </strong>
                </p>
                <p>
                  L’utente ha la possibilità di scelta all’uso e alla
                  divulgazione da parte della società di dati personali per
                  finalità commerciali. L’utente può rifiutare esplicitamente:
                </p>
                <ul>
                  <li>
                    <strong>
                      di ricevere comunicazioni di marketing dalla società:
                    </strong>{" "}
                    per non ricevere più altre comunicazioni di marketing dalla 
                    società, l’utente può revocare il proprio consenso 
                    contattando la società tramite e-mail cliccando 
                    {" "}
                    <a
                      href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645pM#etd=::00c?Z9W00Y00_RAcvR900ww$"
                      target="_blank"
                    >
                      qui
                    </a>
                    . Nella richiesta, l’utente dovrà specificare nome e
                    cognome, i(l) tipi(o) di comunicazioni di marketing che non
                    vuole più ricevere e l’indirizzo/gli indirizzi a cui vengono
                    inviate. Ad esempio, se non vuole più ricevere dalla società
                    e-mail di marketing o posta ordinaria, dovrà comunicarcelo e
                    fornirci il suo nome e cognome e l’indirizzo e-mail o di
                    posta. In alternativa, per revocare il proprio consenso alla
                    ricezione di e-mail di marketing dalla società, l’utente può
                    seguire le istruzioni per tale revoca contenute in ogni
                    messaggio.
                    <br />
                    <br />
                  </li>
                  <li>
                    <strong>Ricevere promemoria dalla società:</strong> per non ricevere più promemoria sanitari 
                    dalla società, l’utente può revocare il proprio consenso contattando 
                    la società tramite e-mail cliccando {" "}
                    <a
                      href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645pM#etd=::00c?Z9W00Y00_RAcvR900ww$"
                      target="_blank"
                    >
                      qui
                    </a>
                    . Nella risposta, l’utente dovrà specificare nome e cognome
                    e l’indirizzo e-mail o il numero di telefono a cui vengono
                    recapitati i promemoria.
                    <br />
                    <br />
                  </li>
                  <li>
                    <strong>
                      Condivisione di dati personali dell’utente con consociate
                      e partner terzi:
                    </strong>{" "}
                    se in precedenza l’utente ha accettato di ricevere
                    comunicazioni di marketing da consociate o partner terzi,
                    può revocare il proprio consenso alla condivisione dei suoi
                    dati personali con tali parti per le loro future finalità
                    commerciali, contattando la società tramite e-mail cliccando{" "}
                    <a
                      href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645pM#etd=::00c?Z9W00Y00_RAcvR900ww$"
                      target="_parent"
                    >
                      qui
                    </a>
                    . Nella comunicazione effettuata alla società, l’utente
                    dovrà specificare che la società non dovrà più condividere i
                    suoi dati personali con le proprie consociate e/o partner
                    terzi per le loro finalità commerciali, e dovrà includere il
                    suo nome e cognome e indirizzo e-mail.
                  </li>
                </ul>
                <p>
                La società cercherà di soddisfare la(e) richiesta(e) dell’utente nel 
                più breve tempo possibile. Nel caso in cui l’utente decida di revocare 
                il proprio consenso ai servizi appena descritti, la società non potrà 
                eliminare i suoi dati personali dai database delle proprie consociate 
                con le quali erano state condivise in precedenza tali informazioni 
                personali (cioè, a partire dalla data in cui la società rende effettiva 
                la richiesta di revoca del consenso dell’utente). Comunque, la società si 
                attiverà per cercare di informare le consociate della richiesta. Inoltre, 
                nel caso in cui l’utente decida di revocare il proprio consenso alla ricezione 
                di messaggi di marketing dalla società, quest’ultima potrà comunque inviargli 
                importanti messaggi amministrativi e su operazioni societarie, dalla cui 
                ricezione l’utente non potrà esimersi.
                </p>
                <p>
                  <strong>
                    Come accedere, modificare o eliminare i dati personali
                    dell’utente
                  </strong>
                </p>
                <p>
                Per consultare, correggere, aggiornare o eliminare i dati personali 
                oppure per richiedere una copia elettronica dei propri dati personali 
                allo scopo di trasmetterla a un’altra società (nella misura in cui tali 
                diritti siano consentiti dalla legge vigente), l’utente può contattarci 
                attraverso e-mail cliccando {" "}
                  <a
                    href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645pM#etd=::00c?Z9W00Y00_RAcvR900ww$"
                    target="_blank"
                  >
                    qui
                  </a>
                  . La società risponderà alla richiesta dell’utente nel più
                  breve tempo possibile e non più tardi di un mese dalla
                  ricezione. In circostanze in cui si verifica un ritardo nella
                  risposta da parte della società, l’utente sarà immediatamente
                  avvisato e gli sarà fornita una data in cui riceverà la
                  risposta.{" "}
                </p>
                <h2>TRASFERIMENTI INTERNAZIONALI</h2>
                <p>
                I dati personali dell’utente potrebbero essere archiviati ed elaborati 
                in qualsiasi Paese in cui la società possiede strutture o fornitori 
                di servizi e, utilizzando il Servizio, tali dati personali potrebbero 
                essere trasferiti in Paesi al di fuori della sua nazione di residenza, 
                inclusi gli Stati Uniti, che potrebbero prevedere norme differenti per 
                la protezione dei dati personali rispetto a quelle del suo Paese. Comunque, 
                la società attua misure contrattuali e altre garanzie adeguate per la protezione dei 
                dati personali, quando vengono trasferite a consociate o a soggetti terzi in altri Paesi.
                </p>
                <p>
                Ad alcuni Paesi non appartenenti allo Spazio economico europeo (SEE) la Commissione 
                europea riconosce il raggiungimento di un adeguato livello di protezione dei dati personali 
                in base agli standard SEE (l’elenco completo di questi Paesi è disponibile{" "}
                  <a
                    href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/adequacy-decisions_it"
                    target="_blank"
                  >
                    qui
                  </a>
                  . Per i trasferimenti dallo Spazio Economico Europeo ai Paesi non considerati 
                  adeguati dalla Commissione europea, la società assicura che siano adottate 
                  misure di sicurezza adeguate, tra le quali quella che il destinatario sia 
                  vincolato da Clausole contrattuali standard UE per la protezione dei dati 
                  personali. L’utente può ottenere una copia di tali misure contattando il 
                  nostro responsabile della protezione dei dati 
                  personali, secondo la sottostante sezione “Contatti”.{" "}
                </p>
                <h2>SICUREZZA</h2>
                <p>
                  La società cerca di adottare ragionevoli misure organizzative,
                  tecniche e amministrative progettate per proteggere i dati
                  personali sotto il suo controllo. Purtroppo nessun sistema di
                  trasmissione o archiviazione di dati attraverso Internet può
                  ritenersi sicuro al 100%. Nel caso in cui l’utente abbia
                  motivo di ritenere che la sua interazione con la società non
                  sia più sicura (ad esempio se ritiene che la sicurezza di un
                  qualunque suo account presso la società sia stata
                  compromessa), è tenuto a comunicarlo immediatamente alla
                  società stessa, contattandola secondo le indicazioni descritte
                  nella sottostante sezione “Contatti”.
                </p>
                <h2>PERIODO DI CONSERVAZIONE</h2>
                <p>
                  La società conserverà i dati personali dell’utente per tutto
                  il periodo necessario o consentito, per le finalità per le
                  quali i dati personali sono state ottenute. I criteri
                  utilizzati per stabilire i periodi di conservazione includono:
                  (i) il periodo di durata del rapporto della società con
                  l’utente e della fornitura del Servizio all’utente; (ii)
                  l’eventuale esistenza di un obbligo legale a cui è soggetta la
                  società; e (iii) l’opportunità della conservazione alla luce
                  della posizione legale della società (ad esempio riguardo alle
                  leggi vigenti relative a limitazioni, contenziosi o indagini
                  normative).
                </p>
                <h2>SITI WEB E SERVIZI DI TERZE PARTI</h2>
                <p>
                Il Servizio potrebbe contenere link a siti di terze parti. La presente 
                Privacy Policy non riguarda, e la società non ne è responsabile, 
                la privacy, le informazioni o altre procedure di terze parti, incluse 
                quelle che gestiscono siti o servizi online (comprendenti, a titolo 
                esemplificativo, le applicazioni) disponibili da questo Servizio o con 
                link presenti su questo Servizio. La disponibilità o l’inserimento di un 
                link a tali siti o proprietà sul Servizio non implica 
                l’accettazione degli stessi da parte della società o delle sue consociate.
                </p>
                <h2>CONTATTI</h2>
                <p>
                  <strong>Johnson &amp; Johnson S.p.A</strong>. con sede a{" "}
                  <strong>00071 Santa Palomba – Pomezia (Roma)</strong>, è la
                  società titolare della raccolta, utilizzo e divulgazione dei
                  dati personali in base alla presente Privacy Policy.
                </p>
                <p>
                  Per qualsiasi domanda relativa a questa Privacy Policy,
                  l'utente potrà contattarci tramite e-mail cliccando{" "}
                  <a
                    href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645pM#etd=::00c?Z9W00Y00_RAcvR900ww$"
                    target="_blank"
                  >
                    qui
                  </a>{" "}
                  o al seguente indirizzo:
                </p>
                <p>
                  Johnson &amp; Johnson S.p.A.
                  <br />
                  Via Ardeatina, Km 23,500
                  <br />
                  00071 Santa Palomba – Pomezia (Roma)
                </p>
                <p>
                  L’utente può inoltre contattare il nostro responsabile della
                  protezione dei dati per il proprio Paese o regione, ove
                  applicabile, all’indirizzo{" "}
                  <a href="mailto:emeaprivacy@kenvue.com">
                  emeaprivacy@kenvue.com
                  </a>
                  .
                </p>
                <h2>PRESENTAZIONE DI UN RECLAMO A UN'AUTORITA' DI CONTROLLO</h2>
                <p>
                  L’utente può presentare un reclamo a all'autorità di controllo
                  competente per il proprio Paese. Fare clic{" "}
                  <a
                    href="http://ec.europa.eu/justice/data-protection/article-29/structure/data-protection-authorities/index_en.htm"
                    target="_blank"
                  >
                    qui
                  </a>{" "}
                  per le informazioni di contatto di tali autorità.{" "}
                </p>
                <h2>AGGIORNAMENTI ALLA PRESENTE PRIVACY POLICY</h2>
                <p>
                La società potrebbe apportare delle modifiche alla presente Privacy Policy. 
                Tutte le modifiche apportate entreranno in vigore non appena sarà pubblicata 
                sul Servizio la versione rivista della presente Privacy Policy. L’utilizzo del 
                Servizio in seguito a queste modifiche costituirà l’accettazione dell'utente della 
                Privacy Policy aggiornata. La società consiglia di consultare regolarmente la Privacy 
                Policy quando si visita il Servizio. 
                 
                </p>
                <p>
                L’ultimo aggiornamento della presente Privacy Policy è stato eseguito il 04/05/2023.
                </p>
                <p> </p>
              </div>
            </section>
            <section id="social">
              <a
                href="https://www.facebook.com/PizBuinItalia?fref=ts"
                target="_blank"
                className="fb"
              >
                &nbsp;
              </a>
              <a
                href="https://instagram.com/pizbuin_italia/"
                target="_blank"
                className="inst"
              >
                &nbsp;
              </a>
            </section>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
          <div id="product-details-overlay" className="itemDescription"></div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.js" />
        <script src="/assets/js/vendor/jquery.fancybox-media.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n// Conversion Name: LP/HP Piz buin\n// INSTRUCTIONS \n// The Conversion Tags should be placed at the top of the <BODY> section of the HTML page. \n// In case you want to ensure that the full page loads as a prerequisite for a conversion \n// being recorded, place the tag at the bottom of the page. Note, however, that this may \n// skew the data in the case of slow-loading pages and in general not recommended. \n//\n// NOTE: It is possible to test if the tags are working correctly before campaign launch \n// as follows:  Browse to http://bs.serving-sys.com/Serving/adServer.bs?cn=at, which is \n// a page that lets you set your local machine to 'testing' mode.  In this mode, when \n// visiting a page that includes an conversion tag, a new window will open, showing you \n// the data sent by the conversion tag to the MediaMind servers. \n// \n// END of instructions (These instruction lines can be deleted from the actual HTML)\nvar ebRand = Math.random()+'';\nebRand = ebRand * 1000000;\ndocument.write('<scr'+'ipt src=\"HTTP://bs.serving-sys.com/Serving/ActivityServer.bs?cn=as&amp;ActivityID=628188&amp;rnd=' + ebRand + '\"></scr' + 'ipt>');\n"
          }}
        />
        <noscript
          dangerouslySetInnerHTML={{
            __html:
              '\n         <img width="1" height="1" style="border:0" src="HTTP://bs.serving-sys.com/Serving/ActivityServer.bs?cn=as&amp;ActivityID=628188&amp;ns=1">\n      '
          }}
        />
        
        <script
          dangerouslySetInnerHTML={{
            __html:
              '\n$(document).ready(function(){\nvar i = $("#bgImage img").attr(\'src\');\n$("#bgImage").css("background-image","url("+i+")");\n$("#bgImage img").remove();\n});\n'
          }}
        />
      </div>
    );
  }
}

export default Page;
